import React from 'react';

const Languages = () => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Languages</h2>
      <ul>
        <li>🌎 Fluent in English</li>
        <li>Proficient in German language with a working knowledge.</li>
      </ul>
    </div>
  );
};

export default Languages;