import React from 'react';
import MessageFormatter from './MessageFormatter';

const PDFAnalysisMessage = ({ pdfText, jobDescResult, matchResult, interpretation }) => {
    // Early return if no PDF text
    if (!pdfText) return null;

    return (
        <div className="bg-white rounded-xl p-4 border border-slate-200 mt-2">
            {jobDescResult === true ? (
                <div className="space-y-3">
                    {/* Match Result with MessageFormatter */}
                    {matchResult && (
                        <div className="text-sm text-slate-600">
                            <MessageFormatter content={matchResult} />
                        </div>
                    )}
                    
                    {/* Interpretation with MessageFormatter */}
                    {interpretation && (
                        <div className="border-t border-slate-200 pt-3">
                            <div className="text-xs text-slate-600">
                                <MessageFormatter content={interpretation} />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="text-sm text-slate-600">
                    <MessageFormatter 
                        content={
                            jobDescResult === false 
                                ? "This document does not appear to be a job description."
                                : "Analyzing document..."
                        } 
                    />
                </div>
            )}
        </div>
    );
};

export default PDFAnalysisMessage;