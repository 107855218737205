// components/Chat.js
import React, { useState, useEffect, useRef } from 'react';
import { Upload, Bot, User, MessageSquare, Trash } from 'lucide-react';
import { Button } from '../components/ui/button';
import MessageFormatter from '../components/MessageFormatter';
import PDFAnalysisMessage from '../components/PDFAnalysisMessage';
import BackHomeButton from '../components/BackHomeButton';
import apiClient from '../api/axiosConfig';

const Chat = ({ messages, setMessages, selectedLLM, setSelectedLLM, isUploading, setIsUploading, uploadedFile, setUploadedFile }) => {
    const [prompt, setPrompt] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [pdfText, setPdfText] = useState('');
    const [jobDescResult, setJobDescResult] = useState(null);
    const [matchResult, setMatchResult] = useState(null);
    const [interpretation, setInterpretation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const dropdownRef = useRef(null);

    useEffect(() => {
        // Close dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const chatContainer = document.querySelector('.overflow-y-auto');
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (messages.length === 0) {
            setMessages([{ role: "assistant", content: "Hey! I am Olivier's assistant, ask me any question about him, I will try to answer. Let's get started!" }]);
        }
    }, [messages]);

    useEffect(() => {
        const savedMessages = localStorage.getItem('chatMessages');
        if (savedMessages) {
            setMessages(JSON.parse(savedMessages));
        } else {
            setMessages([{ role: "assistant", content: "Hey! I am Olivier's assistant, ask me any question about him, I will try to answer. Let's get started!" }]);
        }
    }, []);

    useEffect(() => {
        // Adjust textarea height based on content
        const textarea = document.querySelector('textarea');
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }, [prompt]);

    const llmOptions = {
        "Meta": ["llama-3.2-1b-preview"],
        "Mistral AI": ["mixtral-8x7b-32768"],
        "Groq": ["llama3-groq-8b-8192-tool-use-preview"],
        "Google": ["gemma2-9b-it"]
    };

    const examplePrompts = [
        "Can you give me a short summary of his cv?",
        "What are his main achievements?",
        "What are his key skills?",
        "How does he contribute to digital transformation?",
        "What is his experience in transformation?",
        "What are his leadership principles?"
    ];

    const handleChatSubmit = async () => {
        if (!prompt.trim()) return;

        const newMessages = [...messages, { role: "user", content: prompt }];
        setMessages(newMessages);
        setPrompt('');
        setLoading(true);
        setError(null);

        try {
            const response = await apiClient.post('/generate_answer', {
                prompt,
                files: uploadedFile,
                selectedLLM: selectedLLM
            });
            const updatedMessages = [...newMessages, {
                role: "assistant",
                content: response.data.answer
            }];
            setMessages(updatedMessages);
        } catch (error) {
            console.error("Error generating answer:", error);
            const errorMessage = {
                role: "assistant",
                content: `Sorry, I encountered an error: ${error.message}`
            };
            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setLoading(false);
        }
    };

    const handleModelSelect = (model) => {
        setSelectedLLM(model); // This should update the state
        setIsDropdownOpen(false); // Close the dropdown
    };

    // File upload handler
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        setIsUploading(true);
        setUploadedFile(file);
        setLoading(true);
        setError(null);
        const formData = new FormData();
        formData.append('file', file);

        try {
            // Upload PDF and get text
            const response = await apiClient.post('/upload_pdf', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const pdfText = response.data.pdf_text;
            setPdfText(pdfText);

            // Check if it's a job description
            const jobDescResponse = await apiClient.post('/is_job_description', {
                text: pdfText,
                llm: selectedLLM
            });

            const isJobDesc = jobDescResponse.data.result;
            setJobDescResult(isJobDesc);

            // Initialize variables for match results
            let matchResult = null;
            let interpretation = null;

            // Only get match results if it's a job description
            if (isJobDesc === true) {
                const matchResponse = await apiClient.post('/compare_job_description', {
                    text: pdfText,
                    llm: selectedLLM
                });
                matchResult = matchResponse.data.match_result;
                interpretation = matchResponse.data.interpretation;

                setMatchResult(matchResult);
                setInterpretation(interpretation);
            }

            // Create upload message
            const uploadMessage = {
                role: "user",
                content: `Uploaded file: ${file.name}`,
                file: file
            };

            // Create analysis message with all results
            const analysisMessage = {
                role: "assistant",
                content: response.data.answer,
                pdfAnalysis: {
                    pdfText,
                    jobDescResult: isJobDesc,
                    matchResult,
                    interpretation,
                }
            };

            // Update messages state with both upload and analysis
            const newMessages = [...messages, uploadMessage, analysisMessage];
            setMessages(newMessages);
            localStorage.setItem('chatMessages', JSON.stringify(newMessages));

        } catch (error) {
            console.error('[Error] Processing failed:', error);
            const errorMessage = {
                role: "assistant",
                content: `Sorry, I encountered an error: ${error.message}`
            };
            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setIsUploading(false);
            setLoading(false);
        }
    };

    return (
        <div className="w-full">
            <div className="lg:w-full flex justify-center">
                <div className="relative inline-block text-left w-full max-w-3xl px-4">
                    {/* Back Home Button */}
                    <div className="flex justify-start mb-4">
                        <BackHomeButton />
                    </div>
                    <div className="flex flex-col space-y-6 max-w-4xl mx-auto">
                        {/* Chat History */}
                        <div className="bg-white rounded-2xl p-6 shadow-sm border border-slate-200">
                            <div className="max-h-[600px] overflow-y-auto flex flex-col space-y-6 scroll-smooth">
                                {messages.length === 0 ? (
                                    <div className="flex flex-col items-center justify-center py-12 text-slate-500">
                                        <Bot size={32} className="mb-4 text-slate-400" />
                                        <p className="text-sm">No messages yet. Start a conversation!</p>
                                    </div>
                                ) : (
                                    messages.map((msg, index) => (
                                        <div
                                            key={index}
                                            className={`flex ${msg.role === 'assistant' ? 'justify-start' : 'justify-end'} group`}
                                        >
                                            <div className={`flex items-start max-w-[80%] space-x-3 ${msg.role === 'assistant' ? 'flex-row' : 'flex-row-reverse'}`}>
                                                <div className={`w-8 h-8 rounded-full flex items-center justify-center ${msg.role === 'assistant' ? 'bg-blue-100' : 'bg-slate-100'}`}>
                                                    {msg.role === 'assistant' ? (
                                                        <Bot size={16} className="text-blue-600" />
                                                    ) : (
                                                        <User size={16} className="text-slate-600" />
                                                    )}
                                                </div>

                                                <div className={`relative rounded-2xl p-4 ${msg.role === 'assistant' ? 'bg-white border border-slate-200' : 'bg-blue-600 text-white'}`}>
                                                    <div className="text-xs mb-1 font-medium">
                                                        {msg.role === 'assistant' ? 'Assistant' : 'You'}
                                                    </div>
                                                    <div className="whitespace-pre-wrap text-sm">
                                                        <MessageFormatter content={msg.content} />
                                                    </div>
                                                    {msg.pdfAnalysis && (
                                                        <PDFAnalysisMessage
                                                            pdfText={pdfText}
                                                            jobDescResult={jobDescResult}
                                                            matchResult={matchResult}
                                                            interpretation={interpretation}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                        {/* Chat box */}
                        <div className="bg-white rounded-2xl p-4 border border-slate-200 shadow-sm">
                            {/* Example Prompts Section */}
                            <div className="px-3 py-3 mb-3 bg-blue-50 rounded-md border border-blue-100 shadow-sm">
                                <h3 className="text-sm font-medium text-blue-700 mb-2">What you could ask...</h3>
                                <div className="flex flex-wrap gap-2 overflow-auto max-h-24">
                                    {examplePrompts.map((examplePrompt, index) => (
                                        <button
                                            key={index}
                                            onClick={() => setPrompt(examplePrompt)}
                                            className="text-xs px-3 py-1.5 rounded-full bg-blue-100 hover:bg-blue-200 text-blue-700 transition-colors hover:text-blue-900 shadow-sm"
                                        >
                                            {examplePrompt}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            {/* Model Selection */}
                            <div className="py-2 relative inline-block text-left w-full" ref={dropdownRef}>
                                <button
                                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                    className="flex items-center justify-between gap-2 w-full rounded-lg text-xs px-3 py-1.5 bg-blue-100 hover:bg-blue-200 text-blue-700 transition-colors hover:text-blue-900 shadow-sm"
                                >
                                    <span className="truncate">{selectedLLM}</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className={`lucide lucide-chevron-up h-4 w-4 transform transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                                    >
                                        <path d="M6 15l6-6 6 6"></path>
                                    </svg>
                                </button>
                                {/* Dropdown Model List */}
                                {isDropdownOpen && (
                                    <div className="absolute bottom-full mb-2 w-full rounded-lg bg-white shadow-xl z-10">
                                        {Object.entries(llmOptions).map(([category, models]) => (
                                            <div key={category} className="border-b border-gray-200 last:border-0">
                                                <div className="px-4 py-2 text-base font-semibold bg-blue-50 text-blue-700">
                                                    {category}
                                                    <div className="flex flex-col gap-1 p-2">
                                                        {models.map((model) => (
                                                            <div
                                                                key={model}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleModelSelect(model);
                                                                }}
                                                                className={`bg-blue-100 cursor-pointer px-4 py-3 rounded-md transition-colors hover:bg-blue-200 ${selectedLLM === model ? 'bg-primary-100 text-primary-800' : 'text-gray-700'}`}
                                                            >
                                                                <div className="text-sm font-medium">{model}</div>
                                                                <div className="mt-0.5 text-xs text-gray-500">
                                                                    {category === "Meta" ? "Cost-effective with balanced performance." :
                                                                        category === "Mistral AI" ? "Fast and specialized in code." :
                                                                            category === "Groq" ? "Tool-use preview optimized." :
                                                                                "High-scale inference."}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {/* Input Chat box */}
                            <div className="relative flex flex-col space-y-3">
                                <textarea
                                    placeholder="Type your message here..."
                                    value={prompt}
                                    onChange={(e) => setPrompt(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault();
                                            handleChatSubmit();
                                        }
                                    }}
                                    className="w-full rounded-xl border border-slate-200 focus:border-blue-300 focus:ring focus:ring-blue-100 p-3 text-sm bg-white resize-none"
                                    rows={1}
                                />
                                <div className="flex items-center justify-between space-x-2 mt-2">
                                    <button
                                        onClick={() => document.getElementById('file-upload').click()}
                                        className="flex items-center text-sm text-gray-500 hover:text-gray-700"
                                        disabled={isUploading}
                                    >
                                        <Upload className={`w-4 h-4 mr-2 ${isUploading ? 'animate-spin' : ''}`} />
                                        Upload PDF
                                    </button>
                                    <Button
                                        size="sm"
                                        className="bg-blue-500 hover:bg-blue-700 border-blue-200 disabled:opacity-50"
                                        onClick={handleChatSubmit}
                                        disabled={loading}
                                    >
                                        {loading ? 'Generating...' : <MessageSquare className="w-4 h-4" />}
                                    </Button>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        className="hidden"
                                        accept="application/pdf"
                                        onChange={handleFileUpload}
                                    />
                                </div>
                                {/* Message help */}
                                <div className="flex justify-between mt-2">
                                    <button
                                        onClick={() => {
                                            setMessages([{ role: "assistant", content: "Hey! I am Olivier's assistant, ask me any question about him, I will try to answer. Let's get started!" }]);
                                            localStorage.removeItem('chatMessages');
                                        }}
                                        className="text-sm text-gray-500 hover:text-gray-700 flex items-center"
                                    >
                                        <Trash className="w-4 h-4 mr-2" />
                                        Clear
                                    </button>
                                    <span className="text-xs text-slate-500">
                                        Press <kbd className="px-1 py-0.5 bg-slate-100 rounded text-xs">Enter</kbd> to send,
                                        <kbd className="px-1 py-0.5 bg-slate-100 rounded text-xs ml-1">Shift + Enter</kbd> for new line
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* Back Home Button */}
                    <div className="flex justify-start mb-4">
                        <BackHomeButton />
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Chat;