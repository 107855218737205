// components/Home.js
import React from 'react';
import { Linkedin, Mail, FileText, ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';
import olivier from '../images/olivier.jpeg'; // Import the image

// Import the PDF files
const englishCV = require('../media/english_cv.pdf');
const frenchCV = require('../media/french_cv.pdf');

const Home = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div className="max-w-2xl mx-auto px-6 py-8 space-y-12">

            {/* Header Section */}
            <section className="flex flex-col items-center space-y-8">
                <div className="flex items-center space-x-8">
                    <div className="flex-shrink-0">
                        <img src={olivier} alt="Olivier Podio" className="w-32 h-32 rounded-full object-cover shadow-lg" />
                    </div>
                    <h1 className="text-6xl font-extrabold text-slate-900">Olivier Podio</h1>
                </div>
                <p className="text-lg text-slate-700 max-w-xl leading-relaxed text-justify">
                    <span className="font-semibold text-slate-800">Digital Transformation Expert</span> with international experience in aligning IT with business priorities. As a liaison between technology and business teams, I drive communication and efficiency, ensuring digital strategies deliver real value and adhere to corporate objectives. Committed to <span className="font-semibold">ethical AI integration</span> and innovation, my focus lies in secure, compliant digital progress.
                </p>
            </section>

                        {/* Professional Overview */}
                        <section className="bg-white rounded-lg shadow border border-slate-300">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="w-full px-6 py-4 flex items-center justify-between text-slate-700 bg-slate-100 hover:bg-slate-200 transition"
                >
                    <span className="text-lg font-medium">Professional Overview</span>
                    {isOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
                </button>

                {isOpen && (
                    <div className="px-6 py-6 text-slate-600">
                        <h3 className="font-semibold text-slate-800">Innovative and Strategic Leader</h3>
                        <p className="mt-4">
                            With extensive experience in digital transformation across various sectors, I excel in:
                        </p>
                        <ul className="list-disc list-inside mt-3 space-y-2">
                            <li>Guiding strategic digital transformations to maximize impact</li>
                            <li>Implementing AI and emerging technologies for operational excellence</li>
                            <li>Ensuring IT solutions align with business growth objectives</li>
                            <li>Driving change in complex, matrix environments with agile methods</li>
                            <li>Bridging technology and business needs seamlessly</li>
                        </ul>

                        <p className="mt-6">
                            Holding an <span className="font-semibold text-slate-800">Executive MBA from emlyon Business School</span>, I integrate deep technical skills with strategic insight, enabling me to:
                        </p>
                        <ul className="list-disc list-inside mt-3 space-y-2">
                            <li>Enhance IT-business communications and alignments</li>
                            <li>Manage stakeholder dynamics in complex structures</li>
                            <li>Ensure data governance while maintaining compliance</li>
                            <li>Deliver customer-centric innovations in CRM and e-commerce</li>
                        </ul>

                        <h4 className="font-medium text-slate-700 mt-6 mb-3">Key Technologies:</h4>
                        <div className="flex flex-wrap gap-2">
                            {[
                                'Generative AI (GenAI)',
                                'PowerBI',
                                'SAP CX Commerce',
                                'HubSpot',
                                'Salesforce',
                                'SAP C4C',
                                'CI/CD',
                                'Data Governance',
                                'API Integration',
                                'Magento'
                            ].map((tech) => (
                                <span key={tech} className="px-3 py-1 bg-slate-100 rounded-full text-sm text-slate-700 border border-slate-300">
                                    {tech}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </section>
            
            {/* Global Call to Action */}
            <section className="bg-white rounded-lg shadow-lg px-8 py-10 text-center space-y-6">
                <h2 className="text-2xl font-semibold text-slate-800">
                    Discover a New Way to Explore My Profile
                </h2>
                <p className="text-base text-slate-600">
                    Dive into an interactive experience where no one has gone before. Chat directly with my profile, explore my career journey, and get answers to your questions—just like a real conversation.
                </p>

                {/* Main Call-to-Action Button */}
                <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                    <Link to="/chat">
                        <button className="bg-blue-500 text-white font-bold py-2 px-6 rounded shadow hover:bg-blue-600 transition">
                            Start the Conversation
                        </button>
                    </Link>
                </div>

                {/* CV and Contact Links */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8">
                    {/* CV Links */}
                    <div className="space-y-4 text-center">
                        <h2 className="text-lg font-semibold text-slate-700">Curriculum Vitae</h2>
                        <div className="flex flex-col gap-3">
                            <a href={englishCV} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition shadow">
                                <FileText className="w-5 h-5" />
                                <span>English CV</span>
                            </a>
                            <a href={frenchCV} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition shadow">
                                <FileText className="w-5 h-5" />
                                <span>CV Français</span>
                            </a>
                        </div>
                    </div>

                    {/* Contact Links */}
                    <div className="space-y-4 text-center">
                        <h2 className="text-lg font-semibold text-slate-700">Connect with Me</h2>
                        <div className="flex flex-col gap-3">
                            <a href="https://www.linkedin.com/in/olivierpodio/" target="_blank" className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded hover:bg-blue-700 transition shadow">
                                <Linkedin className="w-5 h-5" />
                                <span>LinkedIn</span>
                            </a>
                            <a href="#" className="flex items-center justify-center gap-2 px-6 py-3 bg-gray-400 text-gray-300 rounded shadow cursor-not-allowed" aria-disabled="true">
                                <Mail className="w-5 h-5 text-gray-300" />
                                <span>Contact Me</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;