import React from 'react';

const Recommendations = () => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Recommendations</h2>
      <div className="mb-4">
        <h3 className="text-xl font-semibold">Noémie Berling</h3>
        <p>Chief Financial Officer | Business Partner - Blanchon Group</p>
        <p>From his arrival at Blanchon Group, Olivier has been instrumental in building and driving the digital transformation of the group...</p>
      </div>
      {/* Add more recommendations here */}
    </div>
  );
};

export default Recommendations;