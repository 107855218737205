// src/api/axiosConfig.js
import axios from 'axios';

// Create the api instance
const api = axios.create({
  baseURL: 'https://api.wyzlee.com/api',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Add request interceptor
api.interceptors.request.use(
  (config) => {
    // Log the full request configuration and data
    console.log('Full request config:', config);
    console.log('Request URL:', config.url);
    console.log('Request Method:', config.method);
    console.log('Request Headers:', config.headers);
    console.log('Request Data:', config.data);
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor
api.interceptors.response.use(
  (response) => {
    console.log('Response received:', response);
    return response;
  },
  (error) => {
    console.error('Response error:', error);
    if (error.response?.status === 422) {
      console.error('Validation error:', error.response.data);
    }
    return Promise.reject(error);
  }
);

// Export the API functions
export const generateAnswer = async (promptData) => {
  try {
    const response = await api.post('/generate_answer', {
      prompt: promptData.prompt,
      files: promptData.files || [],
      selectedLLM: promptData.selectedLLM || 'mixtral-8x7b-32768'
    });
    return response.data;
  } catch (error) {
    console.error('Error in generateAnswer:', error);
    throw error;
  }
};

// Export the api instance if needed elsewhere
export default api;
