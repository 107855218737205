import React from 'react';

const Contact = () => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Contact</h2>
      <ul>
        <li>📞 +33(0)7 71 02 40 36</li>
        <li>📩 <a href="mailto:olivier.podio@pm.me">olivier.podio@pm.me</a></li>
        <li>🗺️ Lyon, France</li>
        <li>
          <a href="http://linkedin.com/in/olivierpodio" target="_blank" rel="noopener noreferrer">
            <img src="linkedin_480px.png" alt="LinkedIn" className="inline w-6 h-6" /> @olivierpodio
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Contact;