import React from 'react';

const Skills = () => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Core Competencies</h2>
      <ul>
        <li>Digital Transformation</li>
        <li>AI & Emerging Tech</li>
        <li>CRM Systems</li>
        <li>Change Management</li>
        <li>Agile Methodologies</li>
        <li>E-commerce Leadership</li>
      </ul>
    </div>
  );
};

export default Skills;