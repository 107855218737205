import React from 'react';
import Contact from './Contact';
import Experience from './Experience';
import Education from './Education';
import Languages from './Languages';
import Skills from './Skills';
import Recommendations from './Recommendations';

const Profile = () => {
  return (
    <div className="container mx-auto bg-white p-8 shadow-lg rounded-lg">
      <h1 className="text-4xl font-bold mb-6">Olivier Podio - Digital Transformation Expert</h1>
      <Contact />
      <Experience />
      <Education />
      <Languages />
      <Skills />
      <Recommendations />
    </div>
  );
};

export default Profile;