import React from 'react';
import ReactMarkdown from 'react-markdown';

const MessageFormatter = ({ content }) => {
  const styles = {
    code: "bg-slate-100 p-2 rounded font-mono text-sm my-2 block",
    paragraph: "mb-2",
    list: "list-disc ml-6 mb-2",
    heading: "font-bold text-lg mb-2"
  };

  const components = {
    code: ({ node, inline, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || '');
      return !inline ? (
        <div className="relative group">
          <pre className={styles.code}>
            <code className={className} {...props}>
              {children}
            </code>
          </pre>
        </div>
      ) : (
        <code className="bg-slate-100 px-1 py-0.5 rounded text-sm font-mono" {...props}>
          {children}
        </code>
      );
    },
    p: (props) => <p className={styles.paragraph} {...props} />,
    ul: (props) => <ul className={styles.list} {...props} />,
    h1: (props) => <h1 className={styles.heading} {...props} />,
    h2: (props) => <h2 className={`${styles.heading} text-base`} {...props} />
  };

  return (
    <ReactMarkdown 
      components={components}
      children={content}
      className="prose prose-sm max-w-none text-sm"
    />
  );
};

export default MessageFormatter;