import React from 'react';

const Experience = () => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Professional Experience</h2>
      <div className="mb-4">
        <h3 className="text-xl font-semibold">Digital Transformation Principal Consultant | Capgemini</h3>
        <p>2024 - Present</p>
        <p>Lead on generative AI topics...</p>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-semibold">Digital Transformation Consultant | Wyzlee SAS</h3>
        <p>2022 - Present</p>
        <p>Creation of Wyzlee Company...</p>
      </div>
      {/* Add more experience details here */}
    </div>
  );
};

export default Experience;