// components/BackHomeButton.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Home } from 'lucide-react';

const BackHomeButton = ({ onClick }) => {
    return (
        <div className="sticky top-0">
            <Link to="/">
                <button
                    onClick={onClick}
                    className="flex items-center text-sm text-gray-500 hover:text-gray-700"
                >
                    <Home className="w-4 h-4 mr-2" />
                    Back Home
                </button>
            </Link>
        </div>
    );
};

export default BackHomeButton;