import React from 'react';

const Education = () => {
  return (
    <div className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Education</h2>
      <div className="mb-4">
        <h3 className="text-xl font-semibold">Executive MBA | emlyon Business School</h3>
        <p>2019 - 2022</p>
        <a href="https://certificate.bcdiploma.com/check/45B3C646B65F47B637A549545070A4BE7671DD4E10A388AFC7F15F5F9166F357ODhrREtiQVRvSjZMMFB5anJ4YkkrUXB5NWhOckZ0eW1PNnp2c01IY2c1ZVVOd2VO" target="_blank" rel="noopener noreferrer">
          emlyon executive education Certificate
        </a>
      </div>
    </div>
  );
};

export default Education;