// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import Chat from './pages/Chat';
//import Profile from './pages/Profile';
import Profile from './pages/Profile/Profile';
//import Contact from './pages/Contact';
import './index.css';

const App = () => {
    const [messages, setMessages] = useState([]);
    const [selectedLLM, setSelectedLLM] = useState('llama-3.2-1b-preview');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);

    return (
        <Router>
            <div className="min-h-screen bg-slate-50">
                <Navigation />
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/chat" element={
                            <Chat
                                messages={messages}
                                setMessages={setMessages}
                                selectedLLM={selectedLLM}
                                setSelectedLLM={setSelectedLLM}
                                isUploading={isUploading}
                                setIsUploading={setIsUploading}
                                uploadedFile={uploadedFile}
                                setUploadedFile={setUploadedFile}
                            />
                        } />
                        <Route path="/profile" element={<Profile />} />
                        {/* <Route path="/contact" element={<Contact />} /> */}
                    </Routes>
                </div>
            </div>
        </Router>
    );
};

export default App;